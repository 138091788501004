import React from "react";
import * as styles from "./SliderArrows.module.scss";
import ArrowSvg from "../../assets/svg/arrow.svg";

const SliderArrows = ({ goPrev, goNext }) => (
  <div className={styles.wrapper}>
    <button type="button" onClick={goPrev} className={styles.prev} aria-label="Previous">
      <ArrowSvg />
    </button>
    <button type="button" onClick={goNext} className={styles.next} aria-label="Next">
      <ArrowSvg />
    </button>
  </div>
);

export default SliderArrows;
